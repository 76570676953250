<template>
  <div>
  </div>
</template>

<script lang="js">
import wx from "weixin-js-sdk";
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
export default {
    setup() {
        const userId = ref(0)
        const code = ref(0)
        const getQueryVariable =(variable) =>{
            // console.log(window.location);
            var query = window.location.search.substring(1);
            // var query = '?code=0410ZEFa10AWqG0GZoHa1P5CEM20ZEFS&state=1300848642'.substring(1)
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return decodeURI(pair[1]);
                }
            }
            return false;
        }
        const initPage = () =>{
            let code = getQueryVariable("code")
            console.log(code);
            if (code) {
                wx.miniProgram.navigateTo({
                    url: `/pages/login/index?code=${code}`,
                });
            }else{
                ElMessage({
                    message: "授权失败",
                    type: 'warning',
                })
            }
        }
        initPage()

    return {
         getQueryVariable
        };
  },
    
}
</script>

<style>

</style>